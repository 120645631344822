import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import conObj from '../../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  companies: [],
  leadForUsers: [],
  roles: [],
  callResults: [],
  badges: {
    pendingLeadsCount: 0,
  },
};

const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setLeadForUsers(state, action) {
      state.leadForUsers = action.payload;
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setCallResults(state, action) {
      state.callResults = action.payload;
    },
    setBadges(state, action) {
      state.badges = { ...state.badges, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCompanies, setLeadForUsers, setRoles, setBadges, setCallResults } = slice.actions;

export const fetchCompanies = () =>
  conObj.get('Lookup/Companies').then((response) => {
    dispatch(setCompanies(response.data));
  });

export const fetchLeadForUsers = (companyId) =>
  conObj.get('Lookup/LeadForUsers', { params: { companyId } }).then((response) => {
    dispatch(setLeadForUsers(response.data));
  });

export const fetchRoles = () =>
  conObj.get('Lookup/Roles').then((response) => {
    dispatch(setRoles(response.data));
  });

export const fetchCallResults = () =>
  conObj.get('Lookup/CallResults').then((response) => {
    dispatch(setCallResults(response.data));
  });

export const fetchBadges = () =>
  conObj.get('Lookup/Roles').then((response) => {
    dispatch(setBadges(response.data));
  });
