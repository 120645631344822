// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Typography, Grid } from '@mui/material';
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import Logo from '../../components/Logo';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();

  const lgDown = useResponsive('down', 'lg');

  const lgUp = useResponsive('up', 'lg');

  return (
    <Page title="Login">
      <RootStyle sx={{ background: theme.palette.gradients.primaryDark }}>
        <Grid container justifyContent="center">
          <Grid item xs={10} sm={10} md={7} lg={7}>
            <Grid container component={Card}>
              <Grid item xs={12} sm={12} md={12} lg={6} display="flex" alignItems="center">
                <Stack alignItems="center" sx={{ m: 5, width: '100%' }}>
                  <Stack alignItems={'center'}>
                    {lgDown && <Logo sx={{ width: 90, height: 90 }} />}
                    <Typography variant="h4" gutterBottom mb={4}>
                      Sign in
                    </Typography>
                  </Stack>
                  <Box sx={{ width: '100%' }}>
                    <LoginForm />
                  </Box>
                </Stack>
              </Grid>

              {lgUp && (
                <Grid item lg={6} sx={{ backgroundColor: theme.palette.background.neutral }} display="flex" alignItems="center">
                  <Stack
                    alignItems={'center'}
                    sx={{
                      p: 5,
                    }}
                  >
                    <Logo sx={{ width: 90, height: 90 }} />
                    <Typography variant="h3" sx={{ mb: 5 }}>
                      Hi, Welcome Back
                    </Typography>
                    <Box width={'80%'}>
                      <Image
                        visibleByDefault
                        disabledEffect
                        src="/assets/illustrations/illustration_login.png"
                        alt="login"
                      />
                    </Box>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </RootStyle>
    </Page>
  );
}
