import jwtDecode from 'jwt-decode';
// routes
import moment from 'moment';
//
import axios from 'axios';
import conObj from './connection-assistant';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleReauth = async (uid) => {
  const response = await conObj.get(`Anonymous/Reauth/${uid}`);

  const accessToken = response.data?.jwt;

  setSession(accessToken);
};

const handleTokenExpired = (exp, uid) => {
  let expiredTimer;

  const currentTime = Date.now();

  const convertedExp = moment.unix(exp).valueOf();

  const daysLeft = moment.duration(moment(convertedExp).diff(moment(currentTime))).asDays();

  // setTimeout have a limited duration so max it at five days
  if (daysLeft <= 5) {
    const timeLeft = convertedExp - currentTime;

    clearTimeout(expiredTimer);

    expiredTimer = setTimeout(() => handleReauth(uid), timeLeft);
  }
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // This function below will handle when token is expired
    const { exp, uid } = jwtDecode(accessToken);
    handleTokenExpired(exp, uid);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export { isValidToken, setSession };
