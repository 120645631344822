import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import { hasPermission } from '../utils/getUserType';
import { PATH_DASHBOARD, PATH_LEADS } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return window.location.replace(hasPermission('dbd') ? PATH_DASHBOARD.root : PATH_LEADS.root);
    // return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  return <>{children}</>;
}
