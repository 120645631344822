import PropTypes from 'prop-types';
// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  DialogContent,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { resetConfirmState, setLoading, setOpen } from '../redux/slices/confirm';
import Iconify from './Iconify';
// import { resetConfirmState } from '../redux/slices/confirm';

// ----------------------------------------------------------------------

export default function CustomConfirm() {
  const theme = useTheme();

  const { isLoading, open, title, subTitle, onConfirm, onCancel, isInfo, isDanger } = useSelector(
    (state) => state.confirm
  );

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetConfirmState()), []);

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(setOpen(false));
    dispatch(resetConfirmState());
  };

  const handleConfirm = async () => {
    if (onConfirm && typeof onConfirm === 'function') {
      dispatch(setLoading(true));
      await onConfirm();
      dispatch(setLoading(false));
    }

    dispatch(setOpen(false));
    dispatch(resetConfirmState());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
      sx={isDanger ? { backgroundColor: theme.palette.error.main } : {}}
      transitionDuration={{ enter: 300, exit: 0 }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon={'ep:close'} />
      </IconButton>
      <DialogTitle>
        <Typography fontWeight={'bold'}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Typography component={'div'} variant="body2">
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!isInfo && (
          <Button variant="outlined" color="inherit" onClick={handleCancel}>
            No
          </Button>
        )}

        <LoadingButton
          type="submit"
          variant="contained"
          color={isDanger ? 'error' : 'primary'}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleConfirm}
        >
          {isInfo ? 'Ok' : 'Yes'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
