import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

const PhonenumberTextField = ({ onChange, value, ...others }) => {
  const [callingCode, setCallingCode] = useState('60');
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const handleCallingCodeChange = (event) => {
    setCallingCode(event.target.value);
    onChange(`+${event.target.value}${textFieldValue}`);
  };

  const handleTextFieldChange = (event) => {
    const finalValue = `+${callingCode}${event.target.value}`;
    if (isValidPhoneNumber(finalValue, 'MY')) {
      setTextFieldValue(parsePhoneNumber(finalValue).nationalNumber);
    } else setTextFieldValue(event.target.value);
    onChange(finalValue);
  };

  return (
    <TextField
      value={isValidPhoneNumber(value, 'MY') ? parsePhoneNumber(value).nationalNumber : textFieldValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              size="small"
              sx={{ fontSize: '10px', marginRight: 1 }}
              value={callingCode}
              onChange={handleCallingCodeChange}
              disabled={others?.disabled || false}
            >
              {/* {countries.map((option) => (
                <MenuItem key={option.code} value={option.phone}>
                  {option.code} +{option.phone}
                </MenuItem>
              ))} */}
              <MenuItem value={'60'}>MY</MenuItem>
            </Select>
            +{callingCode}
          </InputAdornment>
        ),
      }}
      {...others}
      onChange={handleTextFieldChange}
    />
  );
};

PhonenumberTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhonenumberTextField;
