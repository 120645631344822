import jwtDecode from 'jwt-decode';

export function getUserPermissions() {
  const jwt = localStorage.getItem('accessToken');

  if (jwt) {
    const { perm } = jwtDecode(jwt);

    return JSON.parse(perm);
  }

  return [];
}

export function hasPermission(value) {
  let isAuthorized = false;
  const perm = getUserPermissions();
  if (value && perm?.length) {
    if (perm.indexOf(value) !== -1) {
      isAuthorized = true;
    }
  }
  return isAuthorized;
}
