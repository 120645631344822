import React from 'react';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import app from '../App';

/**
 * @description
 * axios instance with default
 * baseUrl & headers etc. for global use
 */
const conObj = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // headers, without this, it will throw unsupported media type error
    'content-type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    Accept: 'application/json',
    // Authorization: "Bearer " + localStorage.getItem("jwt")
  },
});

conObj.interceptors.request.use((request) => {
  // Need to check if jwt already expired?
  /**
   * Moved this piece of code here so that localStorage.getItem("jwt")
   * is always getting the latest value.
   * If follow old settings, still need to refresh the whole site to
   * reflect the latest localStorage values
   * */
  const token = localStorage.getItem('accessToken');
  if (token) {
    if (!request.headers.common?.Authorization) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    // const { ucompid } = jwtDecode(token);
    // if (!request.params?.companyId && ucompid) {
    //   request.params = { ...request.params, companyId: ucompid };
    // }

    // if (request.body?.companyId) {
    //   request.body = { ...request.body, companyId: ucompid };
    // }
  }

  // saveLogs(request);
  return request;
});

conObj.interceptors.response.use(
  (response) =>
    // saveLogs(response);
    response,
  (error) => {
    if (!axios.isCancel(error)) {
      if (error?.response?.status === 401) {
        // const lastPath = window.location.hash;
        // sessionStorage.setItem('lastPath', lastPath.substring(1, lastPath.length));
        window.location.replace('/');
      }
      throw error;
    } else console.log('Cancelled');
  }
);

// function saveLogs(req_data) {
//   const data = {
//     secret: 'secret',
//     req_data: req_data,
//     jwt: localStorage.getItem('jwt'),
//   };
//   axios
//     .post(process.env.REACT_APP_LOGGER_API, data, axiosConfig)
//     .then((res) => {
//       //do nothing
//     })
//     .catch((err) => {
//       console.log('Something went wrong while calling save logs: ' + err);
//     });
// }

export const showResponseError = (error, enqueueSnackbar) => {
  if (!enqueueSnackbar) return console.error('enqueueSnackbar parameter is missing');
  const errorMessages = error?.response?.data?.errors;
  const errorMessage = error?.response?.data?.message;

  if (typeof errorMessage === 'string') {
    enqueueSnackbar(errorMessage, { variant: 'error' });
  } else if (errorMessages && Object.keys(errorMessages).length) {
    Object.keys(errorMessages).forEach((key) => enqueueSnackbar(errorMessages[key], { variant: 'error' }));
  } else enqueueSnackbar(error.message, { variant: 'error' });

  return true;
};

export default conObj;
