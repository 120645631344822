import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fMobileNumber(number) {
  if (isValidPhoneNumber(number)) {
    const parsedNumber = parsePhoneNumber(number).formatNational();
    return parsedNumber.replace(/\D/g, '');
  }

  let retValue = number;

  const withCode = /^[+6]{2}/g;
  const withoutCode = /^[6]{1}/g;

  if (withCode.test(number)) retValue = number.replace(withCode, '');
  if (withoutCode.test(number)) retValue = number.replace(withoutCode, '');

  return retValue;
}
