import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Link, Stack, Alert, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// import { getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(4, 'transparent');
  }, []);

  const LoginSchema = Yup.object().shape({
    emailAddress: Yup.string().required().label('Email Address'),
    password: Yup.string().required().label('Password'),
    // captcha: Yup.string().required().label('Captcha'),
  });

  const defaultValues = {
    emailAddress: '',
    password: '',
    captcha: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const validateForm = (value) => {
    if (value.captcha && value.captcha.length !== 4) {
      setError('captcha', {
        type: 'manual',
        message: 'Captcha must be 4 characters!',
      });
      return false;
    }
    if (!validateCaptcha(value.captcha)) {
      setError('captcha', {
        type: 'manual',
        message: 'Captcha Did Not Match!',
      });
      setValue('captcha', '');
      return false;
    }
    clearErrors('captcha');
    return true;
  };

  const onSubmit = async (data) => {
    try {
      if (validateForm(data)) {
        await login(data);
      }
    } catch (error) {
      console.error(error);
      reset();
      loadCaptchaEnginge(4, 'transparent');

      setError('afterSubmit', { ...error, message: error?.response?.data?.message || error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name={'emailAddress'} type="email" label="Email" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack direction={'row'} spacing={1} alignItems="center">
          <RHFTextField name="captcha" label="Captcha Code" inputProps={{ maxLength: 4 }} />
          <Box
            sx={{
              height: 56,
              border: '1px solid rgb(220, 224, 228)',
              borderRadius: '8px',
              pt: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LoadCanvasTemplateNoReload />
          </Box>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent={'end'} sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
