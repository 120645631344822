import React from "react";
import LightboxModal from "./LightboxModal";
import { useDispatch, useSelector } from "../redux/store";
import { unSelectImage } from "../redux/slices/imagePreview";

function LightboxGlobalPreview() {
  const { selectedImage } = useSelector((state) => state.imagePreview);
  const dispatch = useDispatch();

  return (
    <LightboxModal
      images={[selectedImage]}
      mainSrc={selectedImage}
      isOpen={!!selectedImage}
      onCloseRequest={() => dispatch(unSelectImage())}
    />
  );
}

LightboxGlobalPreview.propTypes = {};

export default LightboxGlobalPreview;
